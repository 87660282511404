import * as React from "react"
import { Link } from "gatsby"
import { Container } from 'react-bootstrap'
import BasicLayout from '../layouts/BasicLayout'
import Seo from "../components/seo"
import './styles/index.scss'


export default function NotFoundPage() {
  return (
    <BasicLayout>
      <Seo
        title="404 Not Found"
      />
      <Container className="notfound">
        <h1>404 Page Not Found :</h1>
        <Link to="/">Go home</Link>
      </Container>
    </BasicLayout>
  )
}
